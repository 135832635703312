<template>
    <div class="al-content">
        <div class="About">
            <h1>About</h1>
            <p>
                Eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet.
            </p>

            <h2>Amet sit</h2>
            <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                amet.
            </p>
        </div>
    </div>
</template>

<script>


export default {
  name: `About`,

};
</script>
